<script>
import {  common } from '@/mixins/common';
import { GetApiActionWithAuthorizationOnly, PostApiWithAuthorizationAction } from '../../../helpers/apiActions';

export default {
  name: 'AddIdentifiedItemsToBin',
  props: {
    binNumber: {
        type: [ String, Number ],
        required: true
    },
    applications: {
      type: Array,
      required: false,
      default: () => []
    },
    owners: {
      type: Array,
      required: false,
      default: () => []
    },
    systemSetting: {
        type: Object,
        required: true
    }
  },
  data() {
    var checkBacodeNumber = (rule, value, callback) => {
        const _vm = this
        GetApiActionWithAuthorizationOnly("admin/barcode/check-duplicate-barcode?barcode=" + _vm.form.barcode).then(res => {
            if (res.status == "error") {
                callback(new Error(' '));
            }
            callback();
        });
    };
    return {
        editorConfig: {
            height: 300,
            startupFocus: true
        },
        valid: false,
        form: {
            itemNumber: 1,
            description: '',
            barcode: '',
            selectedOwner: 1,
            selectedEbayApp: [1],
            selectedStoreApp: ''
        },
        rules: {
            barcode: [
                { required: true, message: ' ', trigger: 'change' },
                { validator: checkBacodeNumber, trigger: 'change' }
            ]
        },
        stat: {
            barcodeEnteredCount: 0,
            barcodeSystemCount: 0
        },
        loadedBarcodeCount: false
    }
  },
  mixins: [
    common
  ],
  methods: {
    viewList () {
        this.$root.$emit('display-bin-items-drawer', this.binNumber)
    },
    autoSaveProduct() {
        let _vm = this
        if (_vm.form.barcode.length === _vm.systemSetting.maxBarcodeCount) {
            _vm.submitForm()
        }
    },
    submitForm() {
        let _vm = this
        _vm.$refs.formContainer.validate((valid) => {
            if (valid) {
                PostApiWithAuthorizationAction("admin/products/identifier/save", {
                    ... _vm.form,
                    binNumber: this.binNumber
                })
                .then(res => {
                    if (res.status == 'success') {
                        _vm.resetForm({
                            itemNumber: _vm.form.itemNumber+1
                        })
                    }
                });
            }
        });
    },
    endBin() {
        this.$root.$emit('display-manage-bin-drawer', true)
    },
    resetForm(params = {}) {
        this.form = Object.assign({
            itemNumber: 1,
            description: '',
            barcode: '',
            selectedOwner: 1,
            selectedEbayApp: [1],
            selectedStoreApp: ''
        }, params)
    },
    onEditorReady(editor) {
        editor.model.change(writer => {
            writer.setSelection(editor.model.document.getRoot(), 'end');
        });
        editor.editing.view.focus();
    }
  },
  computed: {
    ebayApps () {
      return this.applications.filter(v=>v.type==='ebay');
    },
    storeApps () {
      return this.applications.filter(v=>v.type==='store');
    }
  },
  mounted () {},
  watch: {
    binNumber: function() {
      this.resetForm()
      this.clearSelection()
    }
  }
}
</script>

<template>
<div class="px-2" style="overflow: hidden">
    <el-form
        :model="form"
        @submit.native.prevent="submitForm"
        ref="formContainer"
        id="identifierForm">
        <div class="mb-1 pt-1 text-center">
            <el-button type="primary" @click="viewList()">
                <i class="fas fa-list-alt"></i> ITEMS LIST
            </el-button>
            <el-button type="danger" @click="endBin()">
                <i class="fas fa-times"></i> END BIN
            </el-button>
        </div>
        <b style="font-size: 25px; color: red;">ITEM #{{ form.itemNumber }}</b>
        <el-form-item
            class="mb-1"
            :rules="rules.description"
            prop="description">
            <label class="mb-0">Description</label>
            <el-input
                type="textarea"
                :rows="5"
                ref="drawerDescriptionField"
                placeholder=""
                v-model="form.description">
            </el-input>
        </el-form-item>
        <el-form-item
            class="mb-1"
            :rules="rules.barcode"
            prop="barcode">
            <label class="mb-0">Barcode</label>
            <el-input
                placeholder=""
                v-model="form.barcode"
                @input="autoSaveProduct()"
                :maxlength="systemSetting.maxBarcodeCount"
                size="medium">
            </el-input>
        </el-form-item>
        <div class="mb-2">
            <label class="mb-0">Ebay Application</label>
            <el-select
                v-model="form.selectedEbayApp"
                placeholder="Ebay App"
                size="medium"
                multiple
                :multiple-limit="1"
            >
                <el-option
                v-for="(ebayApp, index) in ebayApps"
                :key="index"
                :label="ebayApp.name"
                :value="ebayApp.id"
                ></el-option>
            </el-select>
        </div>
        <div class="">
            <label class="mb-0">Owner</label>
            <el-select
                v-model="form.selectedOwner"
                placeholder="# Owner"
                size="medium"
            >
                <el-option
                v-for="(owner, index) in owners"
                :key="index"
                :label="owner.companyName"
                :value="owner.id"
                ></el-option>
            </el-select>
        </div>
        <div class="mt-2 text-right">
            <el-button
                type="primary"
                native-type="submit"
                :disabled="!valid">
                NEW SCAN
            </el-button>
        </div>
    </el-form>
</div>
</template>
<style scoped>
.b-table {
    background-color: rgba(0, 0, 0, 0.05);
    border: 5px solid #5b5f70;
}
</style>
<style>
    #identifierForm .el-form-item__content {
        line-height: 28px;
    }
</style>
