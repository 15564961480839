const tabMap = {
    identified_with_photo: 'Photo\'d',
    identified: 'Identified',
    review: 'Review',
    ready: 'Ready',
    flagged: 'Flagged',
    queue: 'Queue',
    submitted: 'Submitted',
    unsold: 'Relist',
    unpaid: 'Unpaid',
    manual: 'Manual',
    scheduled: 'Scheduled',
    sold: 'Sold',
    unbinned: 'Unbinned',
    archived: 'Archived'
};

export default tabMap