<script>
import {
  PostApiWithAuthorizationAction
} from "@/helpers/apiActions";
import {  common } from '@/mixins/common';

export default {
  components: {},
  props: {
    data: {
      type: Array,
      required: true
    },
    users: {
      type: Array,
      required: true
    },
    productId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: {
        message: ''
      },
      isFormSubmitted: false
    }
  },
  mixins: [
    common
  ],
  methods: {
    deleteComment (id) {
      let _vm = this
      PostApiWithAuthorizationAction("admin/products/delete-comment/" + id).then(res => {
        if (res.status == "success") {
          _vm.$root.$emit('load-comments', _vm.productId)
        }
      }).catch(error => {
        // eslint-disable-next-line
        console.log(error)
        _vm.$toast.error(error.message, {
          position: "top-right",
          timeout: 1000,
          closeOnClick: true
        });
      });
    },
    sendComment () {
      let _vm = this
      _vm.isFormSubmitted = true
      PostApiWithAuthorizationAction("admin/products/add-comment/" + _vm.productId, _vm.form).then(res => {
        if (res.status == "success") {
          _vm.form.message = ''
          _vm.$root.$emit('load-comments', _vm.productId)
        }
      }).catch(error => {
        // eslint-disable-next-line
        console.log(error)
        _vm.$toast.error(error.message, {
          position: "top-right",
          timeout: 1000,
          closeOnClick: true
        });
      }).finally(()=>{
        _vm.isFormSubmitted = false
      });
    }
  },
  computed: {}
}
</script>
<template>
    <div class="demo-drawer__content">
        <div
            class="demo-drawer__comments"
            v-if="data.length==0"
            style="display: flex;align-items: center;">
            <div class="no_comments text-center" style="width: 100%">
              <img src="@/assets/images/comments.png" style="max-width: 150px"/>
            </div>
        </div>
        <div
            class="demo-drawer__comments px-2"
            v-else>
            <div
            class="comment popup-comment-row"
            v-for="(comment, index) in data"
            :key="index">
            <a
                href="javascript:;"
                @click="deleteComment(comment.id)"
                class="popup-drop-comment-btn">
                <i class="fas fa-minus-circle popup-drop-comment-icon"></i>
            </a>
            <span style="color: #08c" v-html="getCommentName(comment.userId)"></span>: {{ comment.message}}
            </div>
        </div>
        <div class="demo-drawer__footer">
            <el-form :model="form" style="width: 100%">
                <b-row class="mb-1 mx-0 px-2">
                    <b-col cols="11" class="px-0">
                      <el-form-item label="" class="mb-1">
                        <el-input
                          type="textarea"
                          v-model="form.message"
                          autofocus
                          placeholder="Type a new message..."
                          rows="4"
                        ></el-input>
                      </el-form-item>
                    </b-col>
                    <b-col cols="1">
                      <el-button
                        type="warning"
                        icon="fas fa-paper-plane"
                        size="small"
                        @click="sendComment()"
                        :loading="isFormSubmitted"
                        circle>
                      </el-button>
                    </b-col>
                </b-row>
            </el-form>
        </div>
    </div>
</template>