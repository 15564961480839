<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  PostApiWithAuthorizationAction
} from "@/helpers/apiActions";
import {  common } from '@/mixins/common';
import { GetApiActionWithAuthorizationOnly, GetApiActionWithAuthorization } from '../../../helpers/apiActions';
import draggable from 'vuedraggable'

export default {
  components: {
    ckeditor: CKEditor.component,
    draggable
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    productItemSpecifics: {
      type: Object,
      required: true
    },
    setting: {
      type: Object,
      required: true
    },
    owners: {
      type: Array,
      required: false,
      default: () => []
    },
    tags: {
      type: Array,
      required: false,
      default: () => []
    },
    applications: {
      type: Array,
      required: false,
      default: () => []
    },
    itemSpecifics: {
      type: Array,
      required: false,
      default: () => []
    },
    storeCategories: {
      type: Array,
      required: false,
      default: () => []
    },
    loadingProductFormSpecific: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    var checkBacodeNumber = (rule, value, callback) => {
        const _vm = this
        GetApiActionWithAuthorizationOnly("admin/barcode/check-duplicate-barcode/" + _vm.product.id + "?barcode=" + _vm.form.barcode).then(res => {
            if (res.status == "error") {
                callback(new Error(' '));
            }
            callback();
        });
    };
    return {
      editor: ClassicEditor,
      editorConfig: {},
      drag: false,
      form: {
        title: '',
        description: '',
        additionalDescription: '',
        binId: '',
        barcode: '',
        selectedOwner: '',
        selectedEbayApp: '',
        selectedStoreApp: '',
        selectedTag: [],
        selectedStoreCategory: [],
        selectedCategory: '',
        condition: '3000',
        enableStoreDiscount: false,
        manualRelist: false,
        publishToSocialNetwork: false,
        editBinNumber: false,
        editBarcode: false,
        itemSpecifics: {},
        startPrice: '0.00',
        reservePrice: '0.00',
        binPrice: '0.00',
        storePrice: '0.00',
        discountPrice: '0.00',
        selectedDiscountType: '0',
        weightInGram: '56.70',
        weightInOz: '2.00',
        weightInLbs: '0',
        weightUnit: 'Gram',
        quantity: '0',
        duration: '7',
        checkSubmitSchedule: false,
        dateSubmitSchedule: '',
        timeSubmitSchedule: '',
        excludePresetFields: []
      },
      selectedPreset: '',
      timesSubmitSchedule: [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00'
      ],
      conditions: [
        { value: '1000', text: 'New' },
        { value: '2000', text: 'Seller Refurbished' },
        { value: '3000', text: 'Used' },
        { value: '4000', text: 'Very Good' },
        { value: '5000', text: 'Good' },
        { value: '6000', text: 'Acceptable' },
        { value: '7000', text: 'For Parts/Not Working' }
      ],
      durations: [1, 3, 5, 7, 10, 14, 21, 30, 60, 90, 120],
      categories: [
        {
          value: '-1',
          data: []
        }
      ],
      enableConditionField: true,
      excludeItemSpecifics: [],
      uploadedPictures: [],
      allowFormSubmission: false,
      showConfirmPresetChangeDialog: false,
      excludeFormSubmissionParams: {
        itemSpecifics: []
      },
      rules: {
        barcode: [
          { validator: checkBacodeNumber, trigger: 'change' }
        ]
      },
      suggestionPresets: [],
      featuredPresets: [],
      paginationPreset: {
        pageSize: 30,
        page: 1
      },
      enablePresetPreviousBtn: false,
      enablePresetNextBtn: true
    }
  },
  mixins: [
    common
  ],
  mounted () {
    let _vm = this
    _vm.fillFormFields()
    _vm.setAttachments()
    _vm.loadPresets()
    _vm.allowFormSubmission = true
    //auto fill weight in gram
    if (parseInt(_vm.form.weightInGram) == 0) {
      _vm.autoFillShippingWeightFields(_vm.product.description)
    }
  },
  methods: {
    extractGram (description) {
      let weightInGrams = /(\d*(\.\d+))\s*(g)/.exec(description) || []
      return (weightInGrams.length > 1) ? weightInGrams[1] : 56.70 // 2 oz in gram
    },
    autoFillShippingWeightFields (description) {
      const _vm = this,
            weightInGrams = _vm.extractGram(description)
      if (weightInGrams && weightInGrams > 0) {
        _vm.form.weightInGram = weightInGrams
        _vm.form.weightInLbs = (weightInGrams * 0.00220462).toFixed(2)
        _vm.form.weightInOz = (weightInGrams * 0.035274).toFixed(2)
      }
    },
    loadPresets () {
      let _vm = this
      return PostApiWithAuthorizationAction("admin/products/load-full-single-product-data-presets/" + _vm.product.id + "?pageSize=" + _vm.paginationPreset.pageSize + "&page=" + _vm.paginationPreset.page, {
        id: _vm.product.id,
        title: _vm.product.title,
        description: _vm.product.description,
        category: _vm.product.category
      }).then(productPresets => {
        if (productPresets.status == "success") {
          _vm.featuredPresets = productPresets.data.featuredPresets
          _vm.suggestionPresets = productPresets.data.suggestionPresets
        }
        return productPresets
      })
    },
    loadPrevPresets () {
      let _vm = this
      _vm.paginationPreset.page = (_vm.paginationPreset.page - 1)
      _vm.loadPresets().then(()=>{
        _vm.enablePresetPreviousBtn = (_vm.paginationPreset.page == 1) ? false : true
        _vm.enablePresetNextBtn = true
      })
    },
    loadNextPresets () {
      let _vm = this
      _vm.paginationPreset.page = (_vm.paginationPreset.page + 1)
      _vm.loadPresets().then(productPresets=>{
        let count = productPresets.count.suggestionPresets || 0
        _vm.enablePresetPreviousBtn = true
        _vm.enablePresetNextBtn = (((_vm.paginationPreset.page + 2) * _vm.paginationPreset.pageSize) >= count) ? false : true
      })
    },
    presetSearchAsync(queryString, cb) {
      GetApiActionWithAuthorization("admin/products/search-preset/" + queryString).then(res => {
        cb(res.data)
      })
    },
    allowSaveItemSpecific(index) {
      let _vm = this
      _vm.itemSpecifics[index].canSelectItemSpecific = false
      _vm.excludeFormSubmissionParams.itemSpecifics.push(_vm.itemSpecifics[index].value)
      //document.getElementById('item-specific-field-' + index).focus()
    },
    saveNewItemSpecific(index) {
      let _vm = this
      let itemSpecific = _vm.itemSpecifics[index].value
      let itemValue = _vm.form.itemSpecifics[itemSpecific]
      let findExcludeIndex = _vm.excludeFormSubmissionParams.itemSpecifics.findIndex(v=>v==itemSpecific)
      if (index > -1) { // only splice array when item is found
        this.$delete(_vm.excludeFormSubmissionParams.itemSpecifics, findExcludeIndex)
      }
      _vm.itemSpecifics[index].canSelectItemSpecific = true
      _vm.itemSpecifics[index].data.push({
        itemValue
      })
      //live save into db
      PostApiWithAuthorizationAction("admin/products/add-item-specific", {
        itemType: _vm.itemSpecifics[index].value,
        itemValue,
        categoryId: _vm.form.selectedCategory
      }).catch(error => {
        // eslint-disable-next-line
        console.log(error)
      });
      //update product item specific
      _vm.submitProductForm()
    },
    getRandomId() {
      return new Date().getUTCMilliseconds()
    },
    handlePictureRotateNext (file) {
      let loader = this.$loading.show({
        container: document.getElementById('uploadedFile' + file.id),
        loader: 'dots',
        color: '#409EFF'
      });
      let _vm = this,
        uploadedPictures = _vm.uploadedPictures,
        findIndex = uploadedPictures.findIndex(v=>v.uid==file.uid);
      PostApiWithAuthorizationAction("admin/products/rotate-picture", {
        id: _vm.product.id,
        original: file.origUrl.split('?')[0],
        thumbnail: file.thumbnailUrl.split('?')[0],
        degree: 90,
        appId: file.appId,
        reverse: false,
        attachmentId: file.id
      }).then(res => {
        file.origUrl = res.data.original + "?id=" + _vm.getRandomId()
        file.thumbnailUrl = res.data.thumbnail + "?id=" + _vm.getRandomId()
        uploadedPictures[findIndex] = file
        _vm.uploadedPictures = uploadedPictures
        document.getElementById("uploadedThumb" + file.id).src = file.thumbnailUrl
      }).catch(error => {
        _vm.$toast.error(error.message, {
          position: "top-right",
          timeout: 1000,
          closeOnClick: true
        });
      }).finally(() => {
        loader.hide()
      });
    },
    handlePictureRotatePrevious (file) {
      let loader = this.$loading.show({
        container: document.getElementById('uploadedFile' + file.id),
        loader: 'dots',
        color: '#409EFF'
      });
      let _vm = this,
        uploadedPictures = _vm.uploadedPictures,
        findIndex = uploadedPictures.findIndex(v=>v.uid==file.uid);
      PostApiWithAuthorizationAction("admin/products/rotate-picture", {
        id: _vm.product.id,
        original: file.origUrl.split('?')[0],
        thumbnail: file.thumbnailUrl.split('?')[0],
        degree: -90,
        appId: file.appId,
        reverse: true,
        attachmentId: file.id
      }).then(res => {
        file.origUrl = res.data.original + "?id=" + _vm.getRandomId()
        file.thumbnailUrl = res.data.thumbnail + "?id=" + _vm.getRandomId()
        uploadedPictures[findIndex] = file
        _vm.uploadedPictures = uploadedPictures
        document.getElementById("uploadedThumb" + file.id).src = file.thumbnailUrl
      }).catch(error => {
        _vm.$toast.error(error.message, {
          position: "top-right",
          timeout: 1000,
          closeOnClick: true
        });
      }).finally(() => {
        loader.hide()
      });
    },
    cancelPresetChange() {
      let _vm = this
      this.$root.$emit('display-product-drawer', this.product.id)
      _vm.showConfirmPresetChangeDialog = false
    },
    acceptPresetChange() {
      let _vm = this
      _vm.showConfirmPresetChangeDialog = false
      _vm.allowFormSubmission = true
      _vm.submitProductForm()
    },
    onEditorReady() {
      this.form.description = this.product.description
    },
    makePreset() {
      this.$root.$emit('make-preset', this.product.id)
    },
    updateStoreCategoryFields() {
      if (!this.setting.storeCategoriesSpecificNames.includes(this.form.selectedStoreCategory[0])
          && !this.setting.storeCategoriesSpecificNames.includes(this.form.selectedStoreCategory[1])) {
        this.enableConditionField = true
        this.excludeItemSpecifics = ['Grade', 'Certification'];
      } else {
        this.excludeItemSpecifics = [];
        this.enableConditionField = false;
      }
    },
    setStoreCategories() {
      this.updateStoreCategoryFields()
      this.submitProductForm()
    },
    setProductCategory() {
      let selectedCategory = this.form.selectedCategory
      this.$root.$emit('load-item-specific-fields', selectedCategory)
      this.form.selectedCategory = ''
      this.loadCategories(selectedCategory)
    },
    changeProductCategory(categoryId) {
      this.form.selectedCategory = ''
      this.loadCategories(categoryId)
    },
    submitProductForm () {
      let _vm = this
      if (_vm.allowFormSubmission) {
        let form = JSON.parse(JSON.stringify(_vm.form))
        if (_vm.excludeFormSubmissionParams.itemSpecifics.length > 0) {
          for(const itemSpecific of _vm.excludeFormSubmissionParams.itemSpecifics) {
            form.itemSpecifics[itemSpecific] = undefined
          }
        }
        //apply auto form update for weight when description change
        let weightInGramFromDescription = _vm.extractGram(_vm.form.description)
        if (weightInGramFromDescription && _vm.form.weightInGram != weightInGramFromDescription) {
          _vm.form.weightInGram = weightInGramFromDescription
          _vm.form.weightInLbs = (weightInGramFromDescription * 0.00220462).toFixed(2)
          _vm.form.weightInOz = (weightInGramFromDescription * 0.035274).toFixed(2)
        } else {
          if (form.weightUnit == 'Oz') {
            form.weightInGram = _vm.unitToGram(form.weightInOz, 'Oz')
            form.weightInLbs = form.weightInOz * 0.0625
          } else if (form.weightUnit == 'Lbs') {
            form.weightInGram = _vm.unitToGram(form.weightInLbs, 'Lbs')
            form.weightInOz = form.weightInLbs * 16
          } else {
            form.weightInLbs = (form.weightInGram * 0.00220462).toFixed(2)
            form.weightInOz = (form.weightInGram * 0.035274).toFixed(2)
          }
        }
        // submit form
        PostApiWithAuthorizationAction("admin/products/save-product/" + _vm.product.id, form).then(() => {
          _vm.$root.$emit('reload-particular-product', _vm.product.id)
        }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
        });
      }
    },
    updateFormWithPreset (id) {
      let _vm = this
      let loader = this.$loading.show({
        container: null,
        loader: 'bars',
        color: '#67C23A'
      });
      GetApiActionWithAuthorization("admin/presets/load-particular-preset/" + id).then(res => {
        _vm.allowFormSubmission = false
        let formFields = {}
        if (!_vm.form.excludePresetFields.includes('Title')) {
          formFields.title = res.data.title
        }
        //
        if (!_vm.form.excludePresetFields.includes('Description')) {
          formFields.description = res.data.itemDescription.trim().length > 0 ? res.data.itemDescription : _vm.form.description
        }
        //
        _vm.form = {
          ... _vm.form,
          ... formFields
        }
        //
        if (res.data.PresetCategories.length > 0) {
          let presetOption = res.data.PresetCategories[0]
          _vm.loadCategories(presetOption.categoryId)
          this.$root.$emit('load-item-specific-fields', presetOption.categoryId)
          _vm.form.condition = presetOption.condition.toString();
        } else {
          _vm.loadCategories(-1)
        }
        _vm.form.weightInGram = res.data.weightInGram;
        _vm.form.selectedStoreCategory = res.storeCategories.labels;
        //auto-set item specifics
        for (let itemSpecificLabel of Object.keys(res.itemSpecifics)) {
          _vm.form.itemSpecifics[itemSpecificLabel] = (typeof _vm.form.itemSpecifics[itemSpecificLabel] !== 'undefined')
                                                        ? res.itemSpecifics[itemSpecificLabel]
                                                        : ""
        }
      }).catch(error => {
        // eslint-disable-next-line
        console.log(error)
        _vm.$toast.error(error.message, {
          position: "top-right",
          timeout: 1000,
          closeOnClick: true
        });
      }).finally(() => {
        loader.hide()
        _vm.showConfirmPresetChangeDialog = true
      });
    },
    handleSelectPreset (item) {
      this.updateFormWithPreset(item.id)
    },
    handlePictureRemove(file) {
      if (confirm('Are you sure that you want delete this picture ?')) {
        let _vm = this
        let loader = this.$loading.show({
          container: document.getElementById('uploadedFile' + file.id),
          loader: 'dots',
          color: '#409EFF'
        });
        PostApiWithAuthorizationAction("admin/products/delete-pictures", { url: file.url }).then(() => {
          document.getElementById('el-upload-list__item_' + file.id).remove()
          _vm.$root.$emit('reload-particular-product', _vm.product.id)
          loader.hide()     
        }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
          loader.hide()
          _vm.$toast.error(error.message, {
            position: "top-right",
            timeout: 1000,
            closeOnClick: true
          });
        })
      }
    },
    handlePicturePreview(file) {
      window.open(file.origUrl, '_blank').focus();
    },
    showCommentDrawer () {
      let _vm = this
      _vm.$root.$emit('display-comment-drawer', {
        show: true,
        id: _vm.product.id
      })
    },
    showProductLogDrawer () {
      let _vm = this
      _vm.$root.$emit('display-product-log-drawer', _vm.product.id)
    },
    previewPictureDrawer () {
      let _vm = this
      _vm.$root.$emit('display-preview-picture-drawer', {
        show: true,
        id: _vm.product.id
      })      
    },
    fillFormFields () {
      let _vm = this
      let selectedEbayApp = _vm.product.ApplicationHasListedAuctions.filter(d=>d.Application.type=='ebay')
      let selectedStoreApp = _vm.product.ApplicationHasListedAuctions.filter(d=>d.Application.type=='store')
      let {
        binId,
        barcode,
        ownerId,
        startPrice,
        binPrice,
        reservePrice,
        storePrice,
        condition,
        manualRelist,
        publishToSocialNetwork,
        weightInGram,
        weightInOz,
        weightInLbs,
        weightUnit,
        discountType,
        discountPrice,
        category,
        allowDiscount,
        duration,
        quantity,
        additionalDescription
      } = _vm.product
      //
      _vm.form = Object.assign(_vm.form, {
        title: _vm.product.title,
        binId,
        barcode,
        selectedOwner: ownerId,
        startPrice: startPrice || '0.00',
        binPrice: binPrice || '0.00',
        reservePrice: reservePrice || '0.00',
        storePrice: storePrice || '0.00',
        discountPrice: discountPrice || '0.00',
        selectedDiscountType: discountType.toString(),
        condition: condition ? condition.toString() : '',
        selectedCategory: parseInt(category || '-1'),
        selectedStoreCategory: _vm.product.AuctionHasStoreCategories.map(s=>s.StoreCategory.name),
        selectedEbayApp: selectedEbayApp.map(t=>t.appId),
        selectedStoreApp: selectedStoreApp.map(t=>t.appId),
        selectedTag: _vm.product.ProductHasTags.map(t=>parseInt(t.productTagId)),
        enableStoreDiscount: allowDiscount,
        manualRelist,
        publishToSocialNetwork,
        duration,
        quantity,
        weightInGram,
        weightInOz,
        weightInLbs,
        weightUnit: weightUnit || 'Gram',
        itemSpecifics: {},
        additionalDescription
      })
      //fill category fields
      _vm.loadCategories()
    },
    loadCategories(categoryId = null)
    {
      let _vm = this,
          params = {},
          url = "admin/ebay-services/get-build-categories";
      if (categoryId == null) {
        categoryId = _vm.form.selectedCategory
      }
      //
      params.categoryId = categoryId;
      return PostApiWithAuthorizationAction(url, params).then(res => {
        if (res.status === 'success') {
          _vm.categories = res.data
          //
          if (res.children.length == 0) {
            _vm.form.selectedCategory = categoryId
            _vm.submitProductForm()
          }
          if (categoryId == -1) {
            _vm.form.selectedCategory = ''
          }
        }
      })
    },
    resetCategories() {
      this.loadCategories(-1)
    },
    beforeUploadPicture () {
      this.$toast.warning('Uploading Picture', {
        position: "bottom-right",
        timeout: false,
        closeOnClick: true,
        icon: 'el-icon-loading'
      });
    },
    afterUpload (response, file, fileList) {
      this.$toast.clear()
      if (response.status == "success") {
        let uid = file.uid
        let findIndex = fileList.findIndex(f=>f.uid==uid)
        fileList[findIndex].url = response.imageUrls[0].thumbnailWatermarkedUrl
        fileList[findIndex].origUrl = response.imageUrls[0].watermarkedUrl
        fileList[findIndex].id = response.imageUrls[0].id
        fileList[findIndex].appId = response.imageUrls[0].appId
        fileList[findIndex].thumbnailUrl = response.imageUrls[0].thumbnailWatermarkedUrl
        this.uploadedPictures = this.uploadedPictures.concat(fileList)
      }
    },
    setAttachments () {
      let _vm = this
      let selectedEbayApp = _vm.form.selectedEbayApp[0]
                            || _vm.product.AuctionAttachments
                                .map(p=>p.appId)
                                .find(p => typeof p !== 'undefined')
      _vm.uploadedPictures = _vm.product.AuctionAttachments
                                .filter(f=>f.appId==selectedEbayApp)
                                .map(v=>{return {...v, url: v.thumbnailUrl}})
                                .sort((p1, p2) => (p1.sort > p2.sort) ? 1 : (p1.sort < p2.sort) ? -1 : 0)
    },
    parseSectionName(section) {
      const sections = section ? section.name.split(':') : ''
      return sections.length > 0 ? sections.join("&nbsp;<b class='preset-nav'><i class='fas fa-caret-right' style='color:red'></i></b>&nbsp;") : ""
    },
    updateList (pictures) {
      return PostApiWithAuthorizationAction('admin/products/sort-pictures', { pictures })
    },
    getPresetTags (tags) {
      return tags.split(',')
    },
    unitToGram (value, unit) {
      let returnValue = 0
      if (unit === 'Oz') {
        returnValue = value * 28.3495
      } else if (unit === 'Lbs') {
        returnValue = value * 453.592
      } else {
        returnValue = value
      }
      return returnValue
    }
  },
  computed: {
    productCharCountColor () {
      let remaningCharacterLength = this.setting.maxCharacterCount - this.product.title.length,
          color = '#5edc0a';
      if (remaningCharacterLength >= 19) {
        color = '#5edc0a';
      } else if (remaningCharacterLength >= 3) {
        color = '#08c';
      } else {
        color = 'red';
      }
      return color;
    },
    ebayApps () {
      return this.applications.filter(v=>v.type==='ebay');
    },
    storeApps () {
      return this.applications.filter(v=>v.type==='store');
    },
    uploadPictureActionUrl () {
      return process.env.VUE_APP_CYBERSHOP_V1_URL + 'api/auction/pictures/v2'
    },
    uploadPictureHeaders () {
      let user = JSON.parse(localStorage.getItem("user"))
      return {
        Authorization: `Bearer ${user.token}` 
      }
    },
    uploadedData () {
      return {
        id: this.product.id
      }
    }
  },
  watch: {
    product: function() {
      let _vm = this
      _vm.fillFormFields()
      this.form.description = this.product.description
      _vm.setAttachments()
      _vm.loadPresets()
    },
    productItemSpecifics: function(value) {
      this.form.itemSpecifics = value
      this.updateStoreCategoryFields()
    },
    'form.weightUnit': function(unit, oldUnit) {
      if (typeof oldUnit !== 'undefined') {
        let _vm = this
        let oldValueInGram = parseFloat(_vm.unitToGram(_vm.form['weightIn' + oldUnit], oldUnit))
        if (unit === 'Oz') {
          _vm.$set(_vm.form, 'weightInOz', (oldValueInGram / 28.35).toFixed(2).replace('.00', ''))
        } else if (unit === 'Lbs') {
          _vm.$set(_vm.form, 'weightInLbs', (oldValueInGram / 453.26).toFixed(2).replace('.00', ''))
        } else {//gram
          _vm.$set(_vm.form, 'weightInGram', oldValueInGram.toFixed(2).replace('.00', ''))
        }
      }
    }
  }
}
</script>
<template>
  <b-row class="mx-0">
    <b-col cols="9">
      <el-form
        class="form-horizontal pt-3"
        @submit.native.prevent="submitProductForm"
        id="productFormContainer"
        :model="form"
        ref="productFormContainer">
        <div class="box-section mb-3 pb-1">
          <div class="box-section-header">GENERAL</div>
          <div class="box-section-body py-0">
          <div class="">
            <b-row class="my-2">
              <b-col cols="9">
                <el-autocomplete
                  class="inline-input mr-1"
                  v-model="selectedPreset"
                  placeholder="Find a preset..."
                  :fetch-suggestions="presetSearchAsync"
                  @select="handleSelectPreset"
                  clearable
                  size="medium"
                  style="width: 90%"
                  placement="bottom"
                  :trigger-on-focus="false"
                  :popper-append-to-body="false"
                >
                  <template slot="prepend">
                    <i class="fas fa-search"></i>
                  </template>
                  <template
                    slot-scope="{item}"
                    v-if="item.id">
                    <span v-html="item.name.replace(/${selectedPreset}/gi, '<b>$0</b>')"></span>
                    <span
                      v-html="parseSectionName(item.Section)"
                      style="float: right;font-style: italic;font-size: 12px">
                    </span>
                  </template>
                </el-autocomplete>
                <el-popover
                  placement="right"
                  width="250"
                  trigger="click">
                  <h4 class="text-center mb-0">SETTINGS</h4>
                  <hr class="my-0"/>
                  <span style="color: red;font-style: italic;">Exculde Overwrite Fields</span>
                  <el-form-item label="" prop="type" class="my-0">
                    <el-checkbox-group v-model="form.excludePresetFields">
                      <el-checkbox label="Title" name="type"></el-checkbox>
                      <el-checkbox label="Description" name="type"></el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>                  
                  <el-button
                    slot="reference"
                    class="inline-input"
                    type="danger"
                    style="padding: 10px;"
                    size="small"
                    circle>
                    <i class="fas fa-cog"></i>
                  </el-button>
                </el-popover>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="9">
                <label class="mb-0">Title</label>
                <el-input
                  for="title"
                  class="productTitle"
                  v-model="form.title"
                  :maxlength="setting.maxCharacterCount"
                  ref="drawerPrimaryField"
                  @input="submitProductForm()"
                  ></el-input>
              </b-col>
              <b-col cols="3">
                <label class="mb-0"></label>
                  <div
                  :style="
                      'color: ' +
                      productCharCountColor +
                      ';font-size: calc(0.9em + 1vmin);font-weight: 600; font-style: italic;'
                  "
                  v-if="form.title.length > 0"
                  >
                  {{
                      setting.maxCharacterCount -
                      form.title.length +
                      " Characters left"
                  }}
                  </div>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="2">
                <label class="mb-0">BIN</label>
                <el-input
                  placeholder="BIN#"
                  v-model="form.binId"
                  @input="submitProductForm()"
                  :maxlength="3"
                  size="medium"
                  v-if="form.editBinNumber">
                  <template slot="prepend">
                    <i class="fas fa-box"></i>
                  </template>
                </el-input>
                <div class="" v-else>
                  <el-button
                    type="danger"
                    size="medium"
                    @click="form.editBinNumber=true">
                    <b>B {{ formatBinNumber(form.binId) }}</b> <i class="fas fa-edit"></i>
                  </el-button>
                </div>
              </b-col>
              <b-col cols="2">
                <el-form-item
                  class="mb-1"
                  :rules="rules.barcode"
                  prop="barcode"
                  v-if="form.editBarcode">
                  <label class="mb-0">Barcode</label>
                  <el-input
                    placeholder="#QrCode"
                    v-model="form.barcode"
                    @input="submitProductForm()"
                    :maxlength="setting.maxBarcodeCount"
                    size="medium">
                    <template slot="prepend">
                      <i class="fas fa-qrcode" style="font-size: 18px"></i>
                    </template>
                  </el-input>
                </el-form-item>
                <div class="" v-else>
                  <label class="mb-0 display-block">Barcode</label>
                  <el-button
                    type="primary"
                    size="medium"
                    @click="form.editBarcode=true">
                    <b>{{ form.barcode }}</b> <i class="fas fa-edit"></i>
                  </el-button>
                </div>
              </b-col>
              <b-col cols="2">
                <label class="mb-0">Owner</label>
                <el-select
                  v-model="form.selectedOwner"
                  @change="submitProductForm()"
                  placeholder="# Owner"
                  size="medium"
                >
                  <el-option
                    v-for="(owner, index) in owners"
                    :key="index"
                    :label="owner.companyName"
                    :value="owner.id"
                  ></el-option>
                </el-select>
              </b-col>
              <b-col cols="3">
                <label class="mb-0">Ebay Application</label>
                <el-select
                  v-model="form.selectedEbayApp"
                  @change="submitProductForm()"
                  placeholder="Ebay App"
                  size="medium"
                  multiple
                  :multiple-limit="1"
                >
                  <el-option
                    v-for="(ebayApp, index) in ebayApps"
                    :key="index"
                    :label="ebayApp.name"
                    :value="ebayApp.id"
                  ></el-option>
                </el-select>
              </b-col>
              <b-col cols="3">
                <label class="mb-0">Store Application</label>
                <el-select
                  v-model="form.selectedStoreApp"
                  @change="submitProductForm()"
                  placeholder="Store App"
                  size="medium"
                  multiple
                >
                  <el-option
                    v-for="(storeApp, index) in storeApps"
                    :key="index"
                    :label="storeApp.name"
                    :value="storeApp.id"
                  ></el-option>
                </el-select>
              </b-col>
            </b-row>
          </div>
          <div class="mb-2">
            <label class="mb-0">Description</label>
            <ckeditor
              v-model="form.description"
              :editor="editor"
              @ready="onEditorReady"
              @input="submitProductForm()"
              :config="editorConfig"></ckeditor>
          </div>
          <div class="mb-2">
            <label class="mb-0">Additional Description</label>
            <el-input
                resize
                type="textarea"
                rows="4"
                placeholder=""
                ref="additionalDescription"
                @input="submitProductForm()"
                v-model="form.additionalDescription">
            </el-input>
          </div>
          <div class="mb-2">
            <label class="mb-0">Category</label>
            <div class="">
              <span
                v-for="(category, index) in categories"
                :key="index">
                <el-select
                  v-model="form.selectedCategory"
                  @change="setProductCategory()"
                  placeholder="--- SELECT CATEGORY ---"
                  v-if="(index+1) == categories.length"
                  class="inline-dropdown"
                  size="medium"
                >
                  <el-option
                    v-for="(option, index) in category.data"
                    :key="index"
                    :label="(option.categoryName + ((option.leafCategory == 0) ? ' >' : ''))"
                    :value="option.categoryID"
                  ></el-option>
                </el-select>
                <span class="mr-1" v-else>
                <el-button
                  type="primary"
                  size="mini"
                  @click="changeProductCategory(category.key)"
                  >
                  {{ category.value }}
                </el-button>
                <i class="fas fa-caret-right mx-1" style="color: red"></i>
                </span>
              </span>
              <el-button
                  type="danger"
                  size="mini"
                  circle
                  icon="el-icon-close"
                  class="mx-1"
                  @click="resetCategories()"
                  >
              </el-button>
            </div>
          </div>
            <b-row class="mb-2">
              <b-col cols="5">
                <label class="mb-0">Tags</label>
                <el-select
                  v-model="form.selectedTag"
                  @change="submitProductForm()"
                  filterable
                  placeholder="Choose Product Tags"
                  size="medium"
                  multiple
                >
                  <el-option
                    v-for="(tag, index) in tags"
                    :key="index"
                    :label="tag.tag"
                    :value="tag.id"
                  ></el-option>
                </el-select> 
              </b-col>
              <b-col cols="4">
                <label class="mb-0">Store Categories</label>
                <el-select
                  v-model="form.selectedStoreCategory"
                  @change="setStoreCategories()"
                  multiple
                  size="medium"
                  placeholder="Choose Store Category"
                  :multiple-limit="2">
                  <el-option
                  v-for="item in storeCategories"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name">
                  </el-option>
                </el-select>
              </b-col>
              <b-col cols="3" v-if="enableConditionField">
                <label class="mb-0">Condition</label>
                <el-select
                  v-model="form.condition"
                  placeholder="Condition"
                  size="medium"
                  @change="submitProductForm()"
                >
                  <el-option
                    v-for="(condition, index) in conditions"
                    :key="index"
                    :label="condition.text"
                    :value="condition.value"
                  ></el-option>
                </el-select>
              </b-col>
            </b-row>
            <div class="text-center">
              <el-checkbox
                v-model="form.enableStoreDiscount"
                label="Activate Discount on stores"
                @change="submitProductForm()"></el-checkbox>
              <el-checkbox
                v-model="form.manualRelist"
                label="Manual List (turn off auto-relisting)"
                @change="submitProductForm()"></el-checkbox>
                <el-checkbox
                  v-model="form.publishToSocialNetwork"
                  label="Publish To Social Network"
                  @change="submitProductForm()"></el-checkbox>
            </div>
          </div>
        </div>
          <div class="mb-2">
            <div class="box-section mb-3">
              <div class="box-section-header">ITEMS SPECIFICS</div>
              <div class="box-section-body pt-0">
                <div
                  class="loading-item-specific text-center"
                  v-if="loadingProductFormSpecific"
                  style="font-size: 20px">
                  <i class="el-icon-loading"></i> Loading Item Specifics
                </div>
                <b-row class="mx-0" v-else>
                <b-col cols="3"
                  v-for="(itemSpecific, index) in itemSpecifics"
                  v-show="!excludeItemSpecifics.includes(itemSpecific.value)"
                  :key="index"
                  class="px-0">
                  <b-row class="mx-0">
                    <b-col cols="9" class="px-0">
                      <label
                        class="mb-0"
                        v-if="product.requiredItemSpecifics.includes(itemSpecific.value) && (form.itemSpecifics[itemSpecific.value] == '' || !form.itemSpecifics[itemSpecific.value])"
                        style="color: red">
                        {{ itemSpecific.value }}
                      </label>
                      <label
                        class="mb-0"
                        v-else>
                        {{ itemSpecific.value }}
                      </label>
                      <el-select
                        v-model="form.itemSpecifics[itemSpecific.value]"
                        :placeholder="itemSpecific.value"
                        v-if="itemSpecific.canSelectItemSpecific"
                        filterable
                        size="medium"
                        @change="submitProductForm()"
                        no-data-text=" "
                      >
                        <el-option
                          v-for="(data, index) in itemSpecific.data"
                          :key="index"
                          :label="data.itemValue"
                          :value="data.itemValue"
                        ></el-option>
                      </el-select>
                      <el-input
                        :placeholder="itemSpecific.value"
                        :id="'item-specific-field-' + index"
                        v-model="form.itemSpecifics[itemSpecific.value]"
                        @input="submitProductForm()"
                        v-else
                        size="medium"></el-input>
                    </b-col>
                    <b-col cols="3" class="px-1 text-center">
                      <label class="mb-0 label-field-only" style="visibility: hidden;display:block">#</label>
                      <el-button
                        type="primary"
                        v-if="itemSpecific.canSelectItemSpecific"
                        icon="fas fa-plus"
                        size="small"
                        @click="allowSaveItemSpecific(index)"
                        circle>
                      </el-button>
                      <el-button
                        type="warning"
                        v-else
                        icon="fas fa-save"
                        @click="saveNewItemSpecific(index)"
                        size="small"
                        circle>
                      </el-button>
                    </b-col>
                  </b-row>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="box-section mb-3">
              <div class="box-section-header">PRICE</div>
              <div class="box-section-body pt-0">
                <b-row class="mb-2">
                  <b-col cols="2">
                    <label class="mb-0">Start Price</label>
                    <el-input
                      placeholder="0.00"
                      v-model="form.startPrice"
                      size="medium"
                      @input="submitProductForm()"></el-input>
                  </b-col>
                  <b-col cols="2">
                    <label class="mb-0">Reserve Price</label>
                    <el-input
                      placeholder="0.00"
                      v-model="form.reservePrice"
                      size="medium"
                      @input="submitProductForm()"></el-input>
                  </b-col>
                  <b-col cols="2">
                    <label class="mb-0">Buy It Now Price</label>
                    <el-input
                      placeholder="0.00"
                      v-model="form.binPrice"
                      size="medium"
                      @input="submitProductForm()"></el-input>
                  </b-col>
                  <b-col cols="2">
                    <label class="mb-0">Store Price</label>
                    <el-input
                      placeholder="0.00"
                      v-model="form.storePrice"
                      size="medium"
                      @input="submitProductForm()"></el-input>
                  </b-col>
                  <b-col cols="4" class="price-field">
                    <label class="mb-0">Discount Price</label>
                    <el-input
                      placeholder="0.00"
                      v-model="form.discountPrice"
                      class="input-with-select"
                      size="medium"
                      @input="submitProductForm()">
                      <el-select
                        v-model="form.selectedDiscountType"
                        slot="prepend"
                        placeholder="Discount Type"
                        size="medium"
                        @change="submitProductForm()">
                        <el-option label="Percent" value="0"></el-option>
                        <el-option label="Flat Amt" value="1"></el-option>
                      </el-select>
                      <template slot="append" v-if="form.selectedDiscountType==0">%</template>
                      <template slot="append" v-else>$</template>
                    </el-input>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="box-section mb-3">
              <div class="box-section-header">SHIPPING</div>
              <div class="box-section-body pt-0">
                <b-row class="mb-2">
                  <b-col cols="3">
                    <label class="mb-0">Weight</label>
                    <el-input
                      for="weight"
                      v-model="form['weightIn' + form.weightUnit]"
                      placeholder="0.00"
                      size="medium"
                      class="shipping-weight-input"
                      @input="submitProductForm()"
                      >
                      <template slot="prepend">
                        <el-select
                          v-model="form.weightUnit"
                          size="medium"
                          @change="submitProductForm()"
                        >
                          <el-option label="Gram" value="Gram"></el-option>
                          <el-option label="Oz" value="Oz"></el-option>
                          <el-option label="Lbs" value="Lbs"></el-option>
                        </el-select>
                      </template>
                    </el-input>
                  </b-col>
                  <b-col cols="2">
                    <label class="mb-0">Quantity</label>
                    <el-input
                      for="quantity"
                      v-model="form.quantity"
                      placeholder="0"
                      size="medium"
                      type="number"
                      @input="submitProductForm()"
                      ></el-input>
                  </b-col>
                  <b-col cols="2">
                    <label class="mb-0">Duration (days)</label>
                    <el-select
                      v-model="form.duration"
                      placeholder="7"
                      size="medium"
                      @change="submitProductForm()"
                    >
                      <el-option
                        v-for="index in durations"
                        :key="index"
                        :label="index"
                        :value="'Days_' + index"
                      ></el-option>
                    </el-select>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <div class="box-section mb-3">
            <div class="box-section-header">PICTURES</div>
            <div class="box-section-body pt-1">
              <div class="text-center">
                <draggable
                  v-model="uploadedPictures"
                  @start="drag=true"
                  @end="drag=false"
                  @update="updateList(uploadedPictures)"
                  class="el-upload-list el-upload-list--picture-card">
                  <div
                    :class="['el-upload-list__item']"
                    :id="'el-upload-list__item_' + file.id"
                    v-for="(file, index) in uploadedPictures"
                    :key="index">
                    <div :ref="'uploadedFile' + file.id" :id="'uploadedFile' + file.id">
                      <el-button
                          icon="fas fa-compress"
                          size="mini"
                          type="warning"
                          v-show="(!file.url.includes('blob'))"
                          class="shift-picture-btn handle"
                          circle
                        ></el-button>
                    <img
                        class="el-upload-list__item-thumbnail"
                        :src="file.url + ((!file.url.includes('blob')) ? '?id=' + getRandomId() : '')"
                        alt=""
                        :id="'uploadedThumb' + file.id"
                        draggable="true"
                        ondragstart="onDragStart(event);"
                      >
                    <span class="el-upload-list__item-actions">
                      <el-button-group>
                        <el-button
                          icon="el-icon-zoom-in"
                          size="mini"
                          type="primary"
                          @click="handlePicturePreview(file)"
                          circle
                        ></el-button>
                        <el-button
                          size="mini"
                          icon="el-icon-delete"
                          type="danger"
                          @click="handlePictureRemove(file, uploadedPictures)"
                          circle
                        ></el-button>
                        <el-button
                          type="success"
                          icon="fas fa-undo"
                          circle
                          size="mini"
                          @click="handlePictureRotatePrevious(file)"
                        ></el-button>
                        <el-button
                          type="success"
                          icon="fas fa-redo"
                          circle
                          size="mini"
                          @click="handlePictureRotateNext(file)"
                        ></el-button>
                      </el-button-group>
                    </span>
                  </div>
                  </div>
                </draggable>
                <el-upload
                  :action="uploadPictureActionUrl"
                  :headers="uploadPictureHeaders"
                  :data="uploadedData"
                  :before-upload="beforeUploadPicture"
                  :on-success="afterUpload"
                  :on-error="afterUpload"
                  :drag="true"
                  :show-file-list="false"
                  style="display: inline"
                  name="files[]"
                  multiple
                  list-type="picture-card">
                  <span slot="default">
                    <el-button
                      size="small"
                      type="primary"
                      icon="el-icon-plus"
                      style="color:white;font-size: 20px"
                      circle>
                    </el-button>
                  </span>
                </el-upload>
              </div>
            </div>
          </div>
      </el-form>
      <b-alert
        style="z-index: 1"
        show
        fade
        v-show="showConfirmPresetChangeDialog"
        variant="danger"
        class="alert-position">
      <div class="text-center mb-1">
        Are you sure that you want to use this preset changes?
        <div class="text-center font-weight-bold" style="font-style:italic">
          <small>(required to allow data syncing in the system)</small>
        </div>
      </div>
      <div class="text-center">
        <el-button
          size="mini"
          type="danger"
          @click="cancelPresetChange()"
        >Cancel</el-button>
        <el-button
          size="mini"
          type="primary"
          @click="acceptPresetChange()"
        >Accept</el-button>
      </div>
      </b-alert>
    </b-col>
    <b-col cols="3" style="background: #fbfbfb;">
      <div class="text-center my-1">
        <el-button
          type="primary"
          icon="fas fa-plus"
          size="mini"
          @click="makePreset()"
          v-if="!product.hasPreset"
          >&nbsp;Preset
        </el-button>
        <el-button
          type="warning"
          icon="fas fa-comment"
          @click="showCommentDrawer()"
          size="mini"
          >&nbsp;Com.
        </el-button>
        <el-button
          type="info"
          icon="fas fa-clipboard-list"
          @click="previewPictureDrawer()"
          size="small"
          circle
          ></el-button>
        <el-button
          type="success"
          icon="fab fa-pagelines"
          size="small"
          @click="showProductLogDrawer()"
          circle
          >
        </el-button>
      </div>
      <div class="text-center">
        <el-popover
          placement="bottom"
          trigger="click"
          v-if="product.orders.length > 0">
          <div v-for="(order, index) in product.orders" :key="index">
            <ul class="product-order-detail p-0" style="margin: 0">
              <li>
                <i class="fas fa-space-shuttle"></i> <i>Order Number:</i>&nbsp;
                <span>
                  <a
                    :href="'https://www.ebay.com/sh/ord/details?' + order.salesRecordNumber + '&orderid=' + order.ebayOrderId"
                    target="_blank"
                    style="color: #f46a6a; font-weight: bold">
                    {{ order.extendedOrderID }}
                  </a>
                </span>
              </li>
              <li>
                <i class="fas fa-space-shuttle"></i> <i>Sales Number:</i>&nbsp;
                <span style="color: #f46a6a; font-weight: bold">{{ order.salesRecordNumber }}</span>
              </li>
              <li>
                <i class="fas fa-space-shuttle"></i> <i>Total Amount:</i>&nbsp;
                <span style="color: #f46a6a; font-weight: bold">${{ order.total }}</span>
              </li>
              <li v-if="order.paidDate > 0">
                <i class="fas fa-space-shuttle"></i> <i>Paid At:</i>&nbsp;
                <span style="color: #f46a6a; font-weight: bold">{{ getFormattedData(order.paidDate) }}</span>
              </li>
              <li v-if="order.shippedDate > 0">
                <i class="fas fa-space-shuttle"></i> <i>Shipped At:</i>&nbsp;
                <span style="color: #f46a6a; font-weight: bold">{{ getFormattedData(order.shippedDate) }}</span>
              </li>
            </ul>
            <div style="text-align: center">
              <a :href="'http://ebay.com/itm/' + product.ebayId" target="_blank">
                <i class="fas fa-angle-double-right"></i> View Item <i class="fas fa-angle-double-left"></i>
              </a>
            </div>
          </div>
          <el-button slot="reference" size="small" type="warning"><i class="el-icon-view"></i> Show Order</el-button>
        </el-popover>
      </div>
      <div class="box-section my-3">
        <div class="box-section-header">FEATURED PRESETS <sup><i class="fas fa-star"></i></sup></div>
        <div class="box-section-body pt-0">
          <div
            v-for="(featuredPreset, index) in featuredPresets"
            :key="index">
            <b-row class="mt-1">
              <b-col cols="10">
                <b-button
                  variant="default"
                  class="el-button--success"
                  size="sm"
                  block
                  @click="updateFormWithPreset(featuredPreset.id)">
                  {{ truncateString(featuredPreset.name.trim(), 30, '...') }}
                </b-button>
              </b-col>
              <b-col cols="2" class="text-center">
                <el-button
                  type="warning"
                  icon="fas fa-pencil-alt"
                  size="mini"
                  circle
                  @click="goToV1Url('setting?tab=presets&presetId=' + featuredPreset.id)">
                </el-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="box-section mb-3">
        <div class="box-section-header">PRESETS SUGGESTIONS</div>
        <div class="box-section-body pt-0">
            <i
              class="fas fa-arrow-circle-left preset-nav-btn preset-nav-btn-previous"
              v-if="enablePresetPreviousBtn"
              @click="loadPrevPresets()"></i>
            <i
              class="fas fa-arrow-circle-right preset-nav-btn"
              @click="loadNextPresets()"
              v-if="enablePresetNextBtn"></i>
          <div
            v-for="(suggestionPreset, index) in suggestionPresets"
            :key="index">
            <b-row class="mt-1">
              <b-col cols="10">
                <el-popover
                  placement="left-start"
                  title=""
                  trigger="hover"
                  v-if="suggestionPreset.PresetTags.length > 0">
                  <b>{{ suggestionPreset.PresetTags[0].matchedCount }}</b> tag(s) matched:
                  <el-tag
                    class="preset-tag-count"
                    v-for="item in getPresetTags(suggestionPreset.PresetTags[0].tags)"
                      :key="item"
                      size="mini"
                      type="danger"
                      effect="dark">
                    {{ item }}
                  </el-tag>
                  <b-button
                    slot="reference"
                    variant="default"
                    class="el-button--success"
                    size="sm"
                    block
                    @click="updateFormWithPreset(suggestionPreset.id)">
                    {{ truncateString(suggestionPreset.name.trim(), 30, '...') }}
                  </b-button>
                </el-popover>
                <b-button
                  variant="default"
                  class="el-button--success"
                  v-else
                  size="sm"
                  block
                  @click="updateFormWithPreset(suggestionPreset.id)">
                  {{ truncateString(suggestionPreset.name.trim(), 30, '...') }}
                </b-button>
              </b-col>
              <b-col cols="2" class="text-center">
                <el-button
                  type="warning"
                  icon="fas fa-pencil-alt"
                  size="mini"
                  circle
                  @click="goToV1Url('setting?tab=presets&presetId=' + suggestionPreset.id)">
                </el-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<style>
  .price-field .el-select .el-input {
    width: 110px;
  }
  .price-field .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
  .el-upload-list--picture-card .el-upload-list__item,
  .el-upload.el-upload--picture-card {
    width: 120px;
    height: 120px;
  }
  .el-upload--picture-card .el-upload-dragger {
    width: 100% !important;
    height: 100% !important;
  }
  .el-upload--picture-card {
    line-height: 120px;
  }
  .alert-position {
    position: fixed;
    bottom: 0;
    width: 30%;
  }
  .ck.ck-editor__editable_inline p {
    margin: 0;
}
#productFormContainer .el-form-item__content {
  line-height: 20px;
}
</style>
