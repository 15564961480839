<script>
import {  common } from '@/mixins/common';

export default {
  components: {},
  props: {
    data: {
      type: Array,
      required: true
    },
    users: {
      type: Array,
      required: true
    }
  },
  data() {
    return {}
  },
  mixins: [
    common
  ],
  methods: {},
  computed: {}
}
</script>
<template>
    <div class="demo-drawer__content">
        <div
            class="demo-drawer__logs"
            v-if="data.length==0"
            style="display: flex;align-items: center;">
            <div class="no_logs text-center" style="width: 100%">
              No logs found
            </div>
        </div>
        <div
            class="demo-drawer__logs px-2"
            v-else>
            <div
            class="log popup-log-row"
            v-for="(log, index) in data"
            :key="index">
                <div v-if="log.user">
                    » {{ log.date }} - {{ log.initGroup ? log.initGroup.name : ""}} ({{ log.status }}) - {{ log.user ? log.user.first_name + " " + log.user.last_name[0] + "." : ""}}
                </div>
            </div>
        </div>
    </div>
</template>