<script>
import {  common } from '@/mixins/common';
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from '../../../helpers/apiActions';

export default {
  name: 'AddItemsToBin',
  components: {},
  props: {
    binNumber: {
        type: [ String, Number ],
        required: true
    }
  },
  data() {
    var checkBacodeNumber = (rule, value, callback) => {
        PostApiWithAuthorizationAction("admin/bin/check-barcode/", {
            barcode: this.form.barcode,
            binId: this.binNumber
        }).then(res => {
            if (res.status == "error") {
                callback(new Error(res.message));
            }
            callback();
        });
    };
    return {
        form: {
            barcode: ''
        },
        rules: {
            barcode: [
                { required: true, message: 'Barcode field is required', trigger: 'submit' },
                { validator: checkBacodeNumber, trigger: 'submit' }
            ]
        },
        stat: {
            barcodeEnteredCount: 0,
            barcodeSystemCount: 0
        },
        loadedBarcodeCount: false,
        systemSetting: {
            maxBarcodeCount: 5
        }
    }
  },
  mixins: [
    common
  ],
  methods: {
    getStat() {
        let _vm = this
        return GetApiActionWithAuthorization("admin/bin/count-data/" + this.binNumber)
        .then(res => {
            _vm.stat.barcodeSystemCount = res.countSystem
            _vm.loadedBarcodeCount = true
        });
    },
    viewList () {
        this.$root.$emit('display-bin-items-drawer', this.binNumber)
    },
    submitForm() {
        let _vm = this
        _vm.$refs.formContainer.validate((valid) => {
            if (valid) {
                PostApiWithAuthorizationAction("admin/bin/set-bin-barcode", {
                    barcode: this.form.barcode,
                    binId: this.binNumber
                })
                .then(() => {
                    _vm.getStat()
                    _vm.stat.barcodeEnteredCount = _vm.stat.barcodeEnteredCount + 1
                    _vm.form.barcode = ''
                });
            }
        });
    },
    endBin() {
        location.reload()
    },
    loadPageData () {
      let _vm = this
      return GetApiActionWithAuthorization("admin/products/get-page-data?dataFilter=settingOnly").then(res => {
        _vm.systemSetting = res.setting
      });
    }
  },
  computed: {},
  mounted () {
    this.loadPageData().then(() => {
        this.getStat()
    })
  },
  watch: {}
}
</script>

<template>
<div class="BinManageForm_content BinManage_content px-2">
    <el-form
        :model="form"
        @submit.native.prevent="submitForm"
        ref="formContainer">
        <div class="mb-3 pt-4 text-center">
            <el-button type="primary" @click="viewList()">
                <i class="fas fa-list-alt"></i> ITEMS LIST
            </el-button>
        </div>
        <el-form-item
            class="mb-1"
            :rules="rules.barcode"
            prop="barcode">
            <el-input
                placeholder="Type/Scan Barcode..."
                ref="mbBarcodeField"
                v-model="form.barcode"
                autofocus
                :maxlength="systemSetting.maxBarcodeCount"
                size="medium">
                <template slot="append">
                    <el-button
                        type="success"
                        native-type="submit"
                        size="small"
                        class="btn-submit-barcode">
                        <i class="fas fa-paper-plane" style="transform: rotate(50deg);"></i>
                    </el-button>
                </template>
            </el-input>
        </el-form-item>
        <div class="text-center" v-if="loadedBarcodeCount">
            <div id="barcount-count-div">
                <span id="span-bin-count-barcode">{{ stat.barcodeEnteredCount }}</span> barcodes entered
            </div>
            <div id="barcount-system-count-div">
                <span id="span-system-bin-count-barcode">{{ stat.barcodeSystemCount }}</span> barcodes in the system
            </div>
        </div>
        <div class="text-center mt-2">
            <el-button type="danger" @click="endBin()">
                <i class="fas fa-times"></i> END BIN
            </el-button>
        </div>
    </el-form>
</div>
</template>
<style scoped>
.b-table {
    background-color: rgba(0, 0, 0, 0.05);
    border: 5px solid #5b5f70;
}
</style>
<style>
.BinManage_content .el-form-item__error {
    color: white;
    padding: 8px;
    background: red;
    border-radius: 5px;
    margin: 4px;
    display: block;
    position: relative !important;
    text-align: center
}
.btn-submit-barcode {
    color: #FFF !important;
    background-color: #67C23A !important;
    border-color: #67C23A !important;
    padding: 12px 13px !important;
    border-radius: 0px 5px 5px 0px !important;
}
</style>