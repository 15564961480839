<script>
import {
  PostApiWithAuthorizationAction
} from "@/helpers/apiActions";

export default {
  props: {
    pictures: {
        type: Array,
        required: true
    }
  },
  data() {
    return {
    }
  },
  methods: {
    deleteProductPicture (attachment) {
      let _vm = this
      PostApiWithAuthorizationAction("admin/products/delete-attachment/" + attachment.id).then(res => {
        if (res.status == "success") {
          _vm.$root.$emit('delete-attachment', attachment.id)
        }
      }).catch(error => {
        // eslint-disable-next-line
        console.log(error)
        _vm.$toast.error(error.message, {
          position: "top-right",
          timeout: 1000,
          closeOnClick: true
        });
      });
    }
  },
  computed: {}
}
</script>
<template>
    <b-carousel
      style="text-shadow: 0px 0px 2px #000;"
      controls
      indicators
      :interval="0"
      fade
      no-animation>
        <b-carousel-slide
          v-for="(attachment, index) in pictures"
          :key="index"
          :img-src="attachment.url"
          :id="'product-attachment-' + index"
        >
            <h5 style="color: #ecf56a">
            Taken By {{ attachment.User.first_name }} {{ attachment.User.last_name }}
            </h5>
            <div class="">
            <b-button-group>
                <a :href="attachment.url" target="_blank">
                <b-button variant="info" size="lg" title="FullScreen">
                    <i class="fas fa-search-plus"></i>
                </b-button>
                </a>
                <b-button variant="primary" size="lg" title="Rotate Left">
                <i class="fas fa-undo-alt"></i>
                </b-button>
                <b-button variant="primary" size="lg" title="Rotate Right">
                <i class="fas fa-redo-alt"></i>
                </b-button>
                <b-button
                    variant="danger"
                    @click="deleteProductPicture(attachment)"
                    size="lg"
                    title="Delete File">
                    <i class="fas fa-trash"></i>
                </b-button>
            </b-button-group>
            </div>
        </b-carousel-slide>
    </b-carousel>
</template>
<style>
  [id^=product-attachment-] img {
    max-height: 90vh !important;
    max-width: 100% !important;
    width: auto !important;
    margin: auto;
  }
  [id^=product-attachment-] {
    text-align: center !important;
  }
  .carousel-caption .btn-group {
    z-index: 10000;
  }
</style>
