<script>
import {  common } from '@/mixins/common';
import { GetApiActionWithAuthorization } from '../../../helpers/apiActions';
import VLazyImage from "v-lazy-image/v2";

export default {
  name: 'ViewBinItemsList',
  components: {
    VLazyImage
  },
  props: {
    binId: {
        type: [ String, Number ],
        required: true
    }
  },
  data() {
    return {
        tableData: [],
        pagination: {
            page_size: 50,
            total: 1,
            page: 1
        },
        columns: [
            {
                key: "id",
                value: 'ID',
                sortable: false,
            },
            {
                key: "picture",
                value: 'Pictures',
                sortable: false,
            },
            {
                key: "title",
                value: 'Title',
                sortable: false,
                center: true
            },
            {
                key: "actions",
                value: 'Actions',
                sortable: false,
                center: true
            }
        ],
    }
  },
  mixins: [
    common
  ],
  methods: {
    loadPageData(params = { page: 1 }) {
        let _vm = this
        return GetApiActionWithAuthorization("admin/bin/list-items/" + _vm.binId, {
            page: params.page
        }).then(res => {
            _vm.tableData = res.data
            _vm.pagination.page_size = res.page_size
            _vm.pagination.total = res.count
            if (res.data.length > 0) {
                _vm.pagination.page = params.page
            }
        });
    },
    updateCurrentPage(page) {
        this.loadPageData({ page })
    },
  },
  computed: {},
  mounted () {
    this.loadPageData({ page: 1 })
  },
  watch: {
    binId: function() {
        this.tableData = []
        this.loadPageData({ page: 1 })
    }
  }
}
</script>

<template>
<div class="products__list_content px-2">
    <div class="table-responsive">
        <table
            role="table"
            aria-busy="false"
            aria-colcount="10"
            class="table b-table"
            >
                <thead
                role="rowgroup"
                class=""
                style="background-color: #74788d; color: white"
                >
                <tr role="row" class="">
                    <th
                    role="columnheader"
                    scope="col"
                    aria-colindex="1"
                    :class="[
                        column.center ? 'text-center': '',
                        'px-4'
                    ]"
                    v-for="column in columns"
                    :key="column.key"
                    >
                    <div>{{ column.value }}</div>
                    </th>
                </tr>
                </thead>
                <tbody role="rowgroup">
                <tr
                    role="row"
                    aria-rowindex="1"
                    class=""
                    v-for="data in tableData"
                    :key="data.id"
                >
                    <td
                        aria-colindex="1"
                        role="cell"
                        style="font-size: 14px"
                        class="col-sm">
                        <div class="">{{ data.id }}</div>
                        <div
                            class="font-weight-bold"
                            style="color: rgb(0, 136, 204);">
                            {{ data.barcode }}
                        </div>
                    </td>
                    <td
                        aria-colindex="1"
                        role="cell"
                        style="font-size: 14px"
                        class="col-sm">
                        <div class=""
                            @click="loadProductPictures(data.id, data.AuctionAttachments.length)">
                            <v-lazy-image
                              class="d-block w-100 img-thumbnail"
                              :src="data.AuctionAttachments[0].thumbnailUrl"
                              v-bind:src-placeholder="require('@/assets/images/image-lazy-loading.gif')"
                              v-if="data.AuctionAttachments.length > 0"
                              />
                            <span v-else>No Picture available</span>
                        </div>
                    </td>
                    <td
                        aria-colindex="1"
                        role="cell"
                        style="font-size: 14px"
                        class="col-sm">
                        {{ data.title }}
                    </td>
                    <td
                        aria-colindex="2"
                        role="cell"
                        :class="['text-center', 'px-0', 'col-sm']">
                        <a
                            :href="'/products/edit/' + data.id"
                            class="btn btn-warning btn-sm"
                            target="_blank">
                            <i class="fas fa-edit"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
        <el-pagination
            background
            layout="prev, pager, next"
            :total="pagination.total"
            :page-size="pagination.page_size"
            @current-change="updateCurrentPage"
            :current-page.sync="pagination.page"
            :pager-count="5"
            v-if="tableData.length > 0">
        </el-pagination>
    </div>
</div>
</template>
<style scoped>
.b-table {
    background-color: rgba(0, 0, 0, 0.05);
    border: 5px solid #5b5f70;
}
</style>
<style>
    .el-pagination.is-background .el-pager li,
    .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .btn-next  {
        border-radius: 20px !important;
        min-width: 34px;
        height: 32px;
        line-height: 34px;
    }
    .img-thumbnail {
        max-width: 110px;
        max-height: 110px;
    }
</style>