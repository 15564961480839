<script>
import {  common } from '@/mixins/common';
import { isMobile, isTablet } from 'mobile-device-detect';

export default {
  name: 'ManageBin',
  components: {},
  props: {
    nextDrawerLabel: {
        type: String,
        required: true
    }
  },
  data() {
    var checkBinNumber = (rule, value, callback) => {
        if (isNaN(value)) {
          return callback(new Error('WARNING: Wrong Bin Barcode Format'));
        }
        callback();
    };
    return {
        form: {
            binNumber: ''
        },
        rules: {
            binNumber: [
                { required: true, message: 'WARNING: Wrong Bin Barcode Format', trigger: 'blur' },
                { min: 3, max: 3, message: 'WARNING: Wrong Bin Barcode Format', trigger: 'blur' },
                { validator: checkBinNumber, trigger: 'blur' }
            ]
        }
    }
  },
  mixins: [
    common
  ],
  methods: {
    getNextDrawerSetting() {
        const _vm = this
        let setting = {}
        switch (this.nextDrawerLabel) {
            case 'startBin':
                setting = {
                    displayManageBinPopup: false,
                    selectedBinId: _vm.form.binNumber,
                    displayAddIdentifiedItemsToBinPopup: true,
                }
                break;
            case 'startBinBulk':
                setting = {
                    displayManageBinPopup: false,
                    selectedBinId: _vm.form.binNumber,
                    displayAddIdentifiedBulkItemsToBinPopup: true
                }
                break;
            default:
                setting = {
                    displayManageBinPopup: false,
                    selectedBinId: _vm.form.binNumber,
                    displayAddItemsToBinPopup: true,
                }
        }
        return setting
    },
    submitForm() {
        let _vm = this
        _vm.$refs.formContainer.validate((valid) => {
            if (valid) {
                if (_vm.nextDrawerLabel == 'startIdentifiedBin') {
                    location.href = '/identifier/create?bin=' + _vm.form.binNumber
                } else if (_vm.nextDrawerLabel == 'startIdentifiedBinBulk') {
                    location.href = '/identifier/createBulk?bin=' + _vm.form.binNumber
                } else {
                    _vm.$root.$emit('display-bin-drawer', _vm.getNextDrawerSetting())
                    _vm.playSuccessSound()
                }
            }
        });
    }
  },
  computed: {
    manageBinLabelDrawerSize () {
      return isMobile ? '100%' : isTablet ? '50%' : '30%'
    },
  },
  mounted () {},
  watch: {}
}
</script>

<template>
<div class="BinManageForm_content BinManage_content px-2">
    <el-form
        :model="form"
        @submit.native.prevent="submitForm"
        ref="formContainer">
        <el-form-item
            class="mb-0"
            :rules="rules.binNumber"
            prop="binNumber">
            <el-input
                type="number"
                placeholder="# BIN Number"
                v-model="form.binNumber"
                ref="drawerPrimaryField"
                :maxlength="3"
                size="medium">
                <template slot="prepend">
                <i class="fas fa-box" style="font-size: 18px"></i>
                </template>
            </el-input>
        </el-form-item>
        <div class="text-right pt-2">
            <el-button
                type="success"
                native-type="submit"
                size="small"
                >Start</el-button>
        </div>
    </el-form>
</div>
</template>
<style scoped>
.b-table {
    background-color: rgba(0, 0, 0, 0.05);
    border: 5px solid #5b5f70;
}
</style>
<style>
.BinManage_content .el-form-item__error {
    color: white;
    padding: 8px;
    background: red;
    border-radius: 5px;
    margin: 4px;
    display: block;
    position: relative !important;
    text-align: center
}
</style>